import React from 'react';
import { createTheme, ThemeProvider, CssBaseline, Typography, Button } from '@mui/material';
import logo from './logo.webp';
import Navbar from './Navbar';
import styled from '@emotion/styled';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import ErrorPage from './ErrorPage';
import SupportPage from './SupportPage';

const theme = createTheme({
  palette: {
    background: {
      default: 'var(--dark-logo)',
    },
    text: {
      primary: '#ffffd1',
    }
  },
});

const Logo = styled('img')`
  height: 60vmin;
  width: 60vmin;
  pointer-events: none;
`;

const AppHeader = styled('header')`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`;

const MainApp = styled('div')`
  text-align: center;
`;


const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <MainApp>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

      </MainApp>
    </ThemeProvider>
  );
}

const Home = () => {
  return <AppHeader>
    <Logo src={logo} className="App-logo" alt="logo" />
    <Typography variant="h3" component="p" style={{ fontFamily: 'PermanentMarker' }}>
      Ordfyran
    </Typography>
    <Button
      variant="contained"
      color="primary"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginTop: '20px' }}
    >
      Download the app
    </Button>
  </AppHeader>
}

export default App;
