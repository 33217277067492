import styled from '@emotion/styled';
import React from 'react'

const PolicyBody = styled('div')`
  padding: 12px;
  text-align: left;
  color: var(--true-white);
`;


const PrivacyPolicy = () => {
    return (
        <PolicyBody >
            <h1>Integritetspolicy för Ordfyran</h1>
            <h2>1. Introduktion</h2>
            <p>Välkommen till Ordfyran! Denna integritetspolicy förklarar hur vi samlar in, använder och skyddar din information när du använder applikationen. Genom att använda Ordfyran godkänner du de metoder som beskrivs i denna policy.</p>
            <h2>2. Informationsinsamling</h2>
            <p>Vi kan samla in följande typer av information för att tillhandahålla och förbättra Ordfyran:</p>
            <ul>
                <li><strong>Personlig information:</strong> När du skapar ett konto samlar vi in detaljer som användarnamn och profilinformation. Vi använder inte din e-postadress för identifiering eller marknadsföring.</li>
                <li><strong>Speldata:</strong> Data om ditt spelande, såsom dina framsteg, poäng och prestationer, samlas in för att erbjuda spelfunktioner och förbättra appen.</li>
                <li><strong>Enhetsinformation:</strong> Information om enheten du använder, såsom enhets-ID, operativsystem och appversion, för att säkerställa kompatibilitet och prestanda.</li>
                <li><strong>Användningsdata:</strong> Genom Firebase Analytics och Google Analytics samlar vi in anonymiserad data om appanvändning, såsom sessionstider, klick och navigering, för att förbättra användarupplevelsen.</li>
                <li><strong>Felrapportering:</strong> Vi använder Firebase Crashlytics för att samla in felrapporter, vilket hjälper oss att identifiera och åtgärda problem som kan påverka din upplevelse.</li>
            </ul>
            <h2>3. Användning av din information</h2>
            <p>Ordfyran använder den insamlade informationen för följande ändamål:</p>
            <ul>
                <li>För att tillhandahålla spelfunktioner som att hålla koll på spelresultat och förbättra spelupplevelsen.</li>
                <li>För att analysera appanvändning genom anonymiserad data för att förbättra funktioner och användarupplevelse.</li>
                <li>För att övervaka och förbättra stabiliteten i appen genom att spåra och hantera kraschrapporter med Firebase Crashlytics.</li>
            </ul>
            <h2>4. Delning av information</h2>
            <p>Vi säljer, byter eller delar inte din personliga information med tredje part, utom i följande fall:</p>
            <ul>
                <li><strong>Tjänsteleverantörer:</strong> Vi kan dela data med betrodda tjänsteleverantörer, såsom Firebase, för att säkert bearbeta och lagra information.</li>
                <li><strong>Juridiska krav:</strong> Om det krävs enligt lag eller för att skydda våra juridiska rättigheter kan vi dela viss information.</li>
            </ul>
            <h2>5. Informationssäkerhet</h2>
            <p>Vi använder säkra tredjepartstjänster för lagring av din data, dock används inte kryptering när appen kommunicerar med våra backend-servrar.</p>
            <h2>6. Barns Integritet</h2>
            <p>Ordfyran är avsedd för en bred publik, och vi samlar inte medvetet in personlig information från barn under 13 år. Om du tror att ditt barn har delat personlig information med oss, vänligen kontakta oss så raderar vi sådan information snarast möjligt.</p>
            <h2>7. Ändringar i Denna Integritetspolicy</h2>
            <p>Vi kan komma att uppdatera denna integritetspolicy regelbundet. Ändringar kommer att publiceras på denna sida, och vi rekommenderar att du granskar policyn regelbundet.</p>
            <h2>8. Kontakta Oss</h2>
            <p>Om du har frågor om denna integritetspolicy eller Ordfyrans dataskyddsmetoder, vänligen kontakta oss på <a href="mailto:developers@gustavsvensk.com">developers@gustavsvensk.com</a>.</p>
            <p>Senast uppdaterad: 2024-11-12</p>
        </PolicyBody>
    )
}

export default PrivacyPolicy
