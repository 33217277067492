import React from 'react'

const SupportPage = () => {
  return (
    <div>
      <h1>Support</h1>
      <p>
      Kontakta oss: <a href="mailto:support@gustavsvensk.com" style={{ color: 'white' }}>support@gustavsvensk.com</a></p>
    </div>
  )
}

export default SupportPage