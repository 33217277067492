import { AppBar, Button, Toolbar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <AppBar position="static" color='primary'>
        <Toolbar>
            <Button color="inherit" component={Link} to="/" sx={{flexGrow: 1, fontFamily: 'PermanentMarker', justifyContent: 'flex-start'}}>
              Ordfyran
            </Button>
            <Button color="inherit" component={Link} to="/privacy-policy">Integritetspolicy</Button>
            <Button color="inherit" component={Link} to="/support">Support</Button>
        </Toolbar>
    </AppBar>
  )
}

export default Navbar